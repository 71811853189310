import './Tour9Sign.css';

function Tour9Sign() {
  return (
    <div className="Tour9Sign">
        <h1>TOUR9</h1>
    </div>
  );
}

export default Tour9Sign;
